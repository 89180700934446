<template>
  <section id="login">
    <v-sheet dark elevation="10">
      <div class="logo">
        <img src="/icon.png" alt="logo" />
      </div>
      <h1 class="text-h4 font-weight-regular">KYC Portal</h1>
      <v-divider class="my-5" />
      <v-form v-model="formIsValid" ref="loginForm" @submit.prevent.stop="attemptLogin">
        <v-text-field
          v-model="username"
          label="Username"
          autocomplete="username"
          prepend-inner-icon="mdi-account"
          light
          solo
          rounded
          hide-details
          required
          class="mb-3"
          :rules="[usernameRequiredRule]"
        />
        <v-text-field
          v-model="password"
          type="password"
          label="Password"
          autocomplete="current-password"
          prepend-inner-icon="mdi-key"
          light
          solo
          rounded
          hide-details
          required
          class="mb-3"
          :rules="[passwordRequiredRule]"
        />
        <v-btn light color="primary" large block rounded type="submit" :loading="loading">
          <span>Login</span>
        </v-btn>
      </v-form>
      <v-divider class="my-5" />
    </v-sheet>
  </section>
</template>

<script>
import { AUTH_LOGIN } from "@/constants/api";

export default {
  name: "Login",
  metaInfo: { title: "Login" },
  data: () => ({
    loading: false,
    username: null,
    password: null,
    formIsValid: false,
    usernameRequiredRule: (v) => !!v || "Username is required",
    passwordRequiredRule: (v) => !!v || "Password is required",
  }),
  methods: {
    async attemptLogin() {
      this.$refs.loginForm.validate();
      if (!this.formIsValid) {
        this.$iziToast.error({
          title: "Error",
          message: "Fill the form correctly",
        });
      }
      try {
        this.loading = true;
        const resp = await this.$axios.post(AUTH_LOGIN, {
          username: this.username,
          password: this.password,
        });
        this.$auth.token = resp.data.data.token;
        window.location.href = "/";
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#login {
  width: 100%;
  height: 100%;
  background-color: #113344;
  background-image: url("/background/wave-haikei.svg");
  background-position: bottom center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;

  .v-sheet {
    background: rgba(black, 0.4);
    text-align: center;
    position: relative;
    padding: 10px;

    @media only screen and (min-width: 420px) {
      padding: 30px;
      width: 400px;
    }
  }

  .logo img {
    height: 128px;
    object-fit: contain;
    object-position: center;
  }
}
</style>
